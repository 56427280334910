import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Heading from '../../components/Heading'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartFailProps {
  navigation: any
  onRetry: Function
}

export default function SmartFail ({ navigation, onRetry }: SmartFailProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Repair Request"/>
      <View style={styles.container}>
        <Heading>Error</Heading>
        <Para>
        Unfortunately there was an error submitting your claim. Please contact us by phone instead.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onRetry} text="Return to main screen"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
