import React, { Component } from 'react';
import { Keyboard, Pressable, StyleSheet, Text, View, Image } from 'react-native'
import Bottom from '../../components/Bottom';
import ConditionalButton from '../../components/ConditionalButton';
import DatePicker from '../../components/DatePicker';
import ScreenHeading from '../../components/ScreenHeading';
import * as moment from 'moment'
import Moment from 'moment';
import ButtonList from '../../components/ButtonList';
import DefaultButton from '../../components/DefaultButton';
import waitingimg from '../../loading-png-gif.gif';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'




const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  listitemViews: {
	flexDirection: 'row'
  },
  listitems: {
	flex: 1,
	paddingLeft: 5,
	fontSize: 16
  },
  rowImage: {
	  height: 200,
    width: 200
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  }
})



class SmartRepairDate extends Component {
  constructor(props) {
    super();
    this.state = {
      date: new Date(),
	  suggested_dates: [],
	  dates_index: 0,
	  current_suggested_date: '',
	  loading: true,
	  arrival_time_text: '',
	  formatted_date: ''
    };
	this.GetShineDates(props);
  }
  
  
  GetShineDates(props) {
	  let theApp = this;
	  this.setState({suggested_dates:[]});
	props.api.shineDatesLookup(props.vehiclePostcode, function done(suggested_dates) {
      if (suggested_dates) {
		if (suggested_dates.length > 0)
		{
    setTimeout(function () {
        const nextState = Object.assign({}, theApp.state, {suggested_dates: suggested_dates});
        theApp.setState(nextState);
		theApp.setState({current_suggested_date: suggested_dates[theApp.state.dates_index]});
		 var date = new Date(suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
		 var formattedDate = Moment(date).format('DD MMM YYYY');
		 console.log(formattedDate);
		 console.log(suggested_dates);
		theApp.setState({formatted_date: formattedDate});
		
		if (suggested_dates[theApp.state.dates_index].Timeslot == "AM")
		{
			theApp.setState({arrival_time_text: 'Between 8:00 am and 10:00am'});
		}
		else
		{
			theApp.setState({arrival_time_text: 'Between 11:30 and 15:30'});
		}
		
		theApp.setState({loading: false});


    }, 5000);
		}
		else
		{
			props.onNoAppointment();
		}

      }
	  else
		{
			props.onNoAppointment();
		}
    }, function notFound() {

    }, function fail(xhr) {
      console.log(xhr);
      //onFail();
    });	 
 }
 
 GetNewShineDate()
 {
	 if (this.state.dates_index < (this.state.suggested_dates.length - 1))
	 {
		 const theApp = this;
		 var new_index = this.state.dates_index+1;
		 this.setState({dates_index: new_index});
		 this.setState({loading: true});
		 setTimeout(function () {
			theApp.setState({current_suggested_date: theApp.state.suggested_dates[theApp.state.dates_index]});
			 var date = new Date(theApp.state.suggested_dates[theApp.state.dates_index].Date.split(' ').join('')); 
			 var formattedDate = Moment(date).format('DD MMM YYYY');
			 console.log(formattedDate);
			theApp.setState({formatted_date: formattedDate});
			if (theApp.state.suggested_dates[theApp.state.dates_index].Timeslot == "AM")
			{
				theApp.setState({arrival_time_text: 'arrival time between 8:00 am and 10:00 am'});
			}
			else
			{
				theApp.setState({arrival_time_text: 'arrival time between 11:30 and 15:30'});
			}
			theApp.setState({loading: false});
			}, 5000);
	
	 }
	 else
	 {
		 this.props.onNoAppointment();
	 }
 }

  render() {
		if (this.state.suggested_dates.length == 0 || this.state.loading == true)
		{
			return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar></NavigationBar>
				  <ScreenHeading text="Book Repair"/>
				  <View style={styles.container}>
					<Para>
					  Please wait... 
					</Para>
					<Para>
					  Do not leave this screen. We are retrieving your appointment date.
					</Para>
					<VerticalSpace height={15}/>
					<View style={styles.imageWrapper} >
						<Image style={styles.rowImage} alt="Waiting..." source={waitingimg}></Image>
					</View>
				  </View>
				</KeyboardAwareScrollView>

			);
		}
		else
		{
			return (
				<KeyboardAwareScrollView style={styles.outerContainer}>
				  <NavigationBar></NavigationBar>
				  <ScreenHeading text="Book Repair"/>
				  <View style={styles.container}>
					<Para>
					  Thank you for your patience.
					</Para>
					<Para>
					  The next available date for your repair is:
					</Para>
					<Para>
					  {this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot}
					</Para>
					<Para>
					  {this.state.arrival_time_text}
					</Para>
					<Para>
					  Do you wish to accept this date and time.
					</Para>
					<VerticalSpace height={15}/>
					<DefaultButton text="Yes" onPress={(e) => this.props.onRepairDateContinue(this.state.formatted_date + " " + this.state.current_suggested_date.Timeslot, this.state.current_suggested_date.Booking_Key)} />
					<VerticalSpace height={15}/>
					<DefaultButton text="No" onPress={(e) => this.GetNewShineDate()} />
					<VerticalSpace height={15}/>
				  </View>
				</KeyboardAwareScrollView>



			);
		}
  }

  setDate(newDate) {
    this.setState({date: newDate});
  }

  canSubmit() {
    return true;
  }
  

}

export default SmartRepairDate;


