import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import LabeledTextField from '../../components/LabeledTextField'
import ConditionalButton from '../../components/ConditionalButton'
import DefaultButton from '../../components/DefaultButton'
import ScreenHeading from '../../components/ScreenHeading'

interface RequestCallbackProps {
  navigation: any
  onSubmit:Function
  onReturn:Function
}

export default function RequestCallback ({ navigation, onSubmit, onReturn }: RequestCallbackProps) {
  const [buttonText, setButtonText] = useState('Submit')
  const details = useSelector((state: RootState) => state.details.value)
  const [contactNumber, setContactNumber] = useState('')
  const [alternativeNumber, setAlternativeNumber] = useState('')

  function canContinue () {
    return contactNumber !== '' && buttonText === 'Submit'
  }
  
  function doContinue() {
    setButtonText('Please Wait...');
    onSubmit(contactNumber, alternativeNumber, () => onFail());
  }
  
  function onFail() {
    setButtonText('Submit');
  }
  
  async function updateContactNumber (text: string) {
    setContactNumber(text);
  }

  async function updateAlternativeNumber (text: string) {
    setAlternativeNumber(text);
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Request callback"/>
      <VerticalSpace height={15} />
      <View style={styles.container}>
		<Para>If you would like us to call you please provide a contact number below.</Para>
        <LabeledTextField placeholder="Required" label="Main contact number" value={contactNumber} onChange={updateContactNumber} />
        <LabeledTextField label="An alternative" value={alternativeNumber} onChange={updateAlternativeNumber} />
        <VerticalSpace height={15} />
        <ConditionalButton onPress={doContinue} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
		<Para>We will respond within 24 hours during normal office opening hours.</Para>
		<Para>Office opening hours:<br />Mon – Fri 9am to 5pm<br />Sat - 9am to 1pm<br />Closed Sunday and Bank Holidays.</Para>
		<VerticalSpace height={15}/>
		<DefaultButton onPress={onReturn} text="Return to menu" />
		<VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
