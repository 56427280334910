import React, { useState } from 'react'
import { View, Picker, StyleSheet } from 'react-native'
import Para from './Para'
import VerticalSpace from './VerticalSpace'
import { setVehiclePostcode } from './../redux/SmartClaimSlice'
import Styles from '../../constants/Styles'

interface AddressSelectOptionsProps {
  addresses: any;
  formatAddress: any;
  onChange: any;
}

export default function AddressSelectOptions ({ addresses, formatAddress, onChange }: AddressSelectOptionsProps) {
	const [selectedValue, setSelectedValue] = useState('s')
  const selectAddress = (event : any) => {
	  console.log(event);
    const index = (event - 1)
	setSelectedValue(index)
    onChange(addresses[index])
    setVehiclePostcode(addresses[index]?.postcode)
	console.log('test')
  }

  if (addresses?.length > 0) {
    return (
      <View>
        <VerticalSpace height={10} />
		<Picker selectedValue={selectedValue} style={styles.selectBox} onValueChange={(value, itemIndex) => selectAddress(itemIndex)}>
			<Picker.Item value="1" label="-- Select address --" />
          {
          addresses.map((address : any, i : number) =>
			<Picker.Item value={i} label={formatAddress(address)} />
			)
		  }
		</Picker>
      </View>
    )
  } else if (addresses?.length === 0) {
    return (
    <Para color={'red'}>We're sorry we cannot find an address using that postcode, please enter the repair address manually.</Para>
    )
  } else {
    return <></>
  }
}

const styles = StyleSheet.create({
  selectBox: {
    fontSize: 16,
	margin: 4,
    borderWidth: 2,
	borderStyle: 'solid',
    height: 35,
	borderColor: 'black',
  }
})
