import React from 'react'
import { StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Submitting from '../../components/Submitting'

interface SmartSubmittingProps {
  navigation: any
}

export default function SmartSubmitting ({ navigation }: SmartSubmittingProps) {
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
        <NavigationBar navigation={navigation}></NavigationBar>
        <Submitting heading="Repair Request"/>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
