import React, { Component } from 'react';
import { Platform } from 'react-native'

import SmartSplash from './SmartSplash';
import SmartIntro from './SmartIntro';
import SmartDate from './SmartDate';
import SmartDateWeb from './SmartDateWeb';
import SmartDamageType from './SmartDamageType';
import SmartDamageLocation from './SmartDamageLocation';
import SmartPhotos from './SmartPhotos';
import SmartSubmitting from './SmartSubmitting';
import SmartAnother from './SmartAnother';
import SmartDifferent from './SmartDifferent';
import SmartDifferentReferred from './SmartDifferentReferred';
import SmartThanks from './SmartThanks';
import SmartPayment from './SmartPayment';
import SmartPaymentNative from './SmartPaymentNative';
import SmartFail from './SmartFail';
import SmartPaymentFail from './SmartPaymentFail';
import SmartVehicleLocationStatement from './SmartVehicleLocationStatement';
import SmartLocation from './SmartLocation';
import SmartRepairDate from './SmartRepairDate';
import SmartNoAppointment from './SmartNoAppointment';
import { clearPhotos, setDamageLocation, setDamageType } from '../../redux/SmartClaimSlice'
import store, { RootState } from '../../redux/Store'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//import { StripeProvider } from '@stripe/stripe-react-native';

const stripeKey = 'pk_test_51JZWHkCwbkoSKLw8Bc8ugZclvqXZYW3u3HCqfNyN4zXAH9xcY8BlURqfcxDp6Or2GI1yyacLdVRDTxXrK0Nyn4P200kpQLU0It';
//const stripePromise = loadStripe('pk_test_wN1ctZrnd2MUHyeosqnbYchL00fb3kSGAE');
const stripePromise = loadStripe(stripeKey);


class Smart extends Component {
  constructor() {
    super();

    this.state = this.freshState();

    this.handleSplashContinue = this.handleSplashContinue.bind(this);
    this.handleIntroContinue = this.handleIntroContinue.bind(this);
    this.handleLocationContinue = this.handleLocationContinue.bind(this);
    this.handleRepairDateContinue = this.handleRepairDateContinue.bind(this);
    this.handleChosenDate = this.handleChosenDate.bind(this);
    this.handleChosenDamageType = this.handleChosenDamageType.bind(this);
    this.handleChosenDamageLocation = this.handleChosenDamageLocation.bind(this);
    this.handlePhotoUploads = this.handlePhotoUploads.bind(this);
    this.handleNoAppointment = this.handleNoAppointment.bind(this);
	this.handleNoOthers = this.handleNoOthers.bind(this);
	this.handleOnLocationNo = this.handleOnLocationNo.bind(this);
	this.handleOnLocationContinue = this.handleOnLocationContinue.bind(this);
	this.handleOnDone = this.handleOnDone.bind(this);
	this.handleOnDoneNoMoreClaims = this.handleOnDoneNoMoreClaims.bind(this);
	this.handlePaymentMade = this.handlePaymentMade.bind(this);
	this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
	this.handleSubmitPaymentNative = this.handleSubmitPaymentNative.bind(this);
	this.handlePaymentFailed = this.handlePaymentFailed.bind(this);
	this.startAgain = this.startAgain.bind(this);
	
    this.apiFail = this.apiFail.bind(this);
  }


  freshState() {
    return {
      stage: 'intro',
      date: null,
      damageType: null,
      damageLocation: null,
      allPhotos: [],
      claimId: null,
      meetsShineBusinessRules: 'false',
      vehiclePostcode: '',
	  vehicleAddress: '',
	  paymentAttempts: 0,
	  paymentReference: ''
    };
  }
  
  handleOnDone() {
	  this.startAgain()
	  this.props.onDone();
  }
  
  handleOnDoneNoMoreClaims() {
	  this.startAgain()
	  this.props.onDoneNoMoreClaims();
  }
  
  

  handleSplashContinue() {
    this.setState({stage: 'chooseDate'});
  }

  handleIntroContinue() {
    this.setState({stage: 'splash'});
  }

  handleLocationContinue(postcode, address) {
    this.setState({vehiclePostcode: postcode});
    this.setState({vehicleAddress: address});
    this.setState({stage: 'chooseRepairDate'});
  }

  handleRepairDateContinue(repair_date, booking_Key) {
    const component = this;
    this.props.api.smartRepairDate(
      this.state.vehiclePostcode,
      this.state.vehicleAddress,
      repair_date,
      booking_Key,
      function done() {
      },
      component.apiFail
    );
	  
	  
	  
    this.setState({stage: 'payment'});
  }
  
  async handlePaymentMade(clientSecret, ConfirmPayment, cardElement) {
	  
	  const my = this;
		
		const paymentSuccess = await ConfirmPayment(clientSecret, cardElement)
	if (paymentSuccess == true)
	{
		this.props.api.shinePaymentMade(
		  this.state.claimId,
		  clientSecret,
		  function done() {
			my.setState({stage: 'different', paymentReference: clientSecret});
			
		  },
		  function fail() {
			my.setState({stage: 'paymentfail', paymentReference: clientSecret});

		  }
		);
	}
	else
	{
		if (my.state.paymentAttempts == 0)
		{
			my.setState({stage: 'paymentfail', paymentReference: clientSecret, paymentAttempts: 1});
		}
		else
		{
			my.setState({stage: 'fail'});
		}
	}

  }
  
  async handlePaymentMadeNative(clientSecret, ConfirmPayment) {
	  
	  const my = this;
		
		const paymentSuccess = await ConfirmPayment(clientSecret)
	if (paymentSuccess == true)
	{
		this.props.api.shinePaymentMade(
		  this.state.claimId,
		  clientSecret,
		  function done() {
			my.setState({stage: 'different', paymentReference: clientSecret});
			
		  },
		  function fail() {
			my.setState({stage: 'paymentfail', paymentReference: clientSecret});

		  }
		);
	}
	else
	{
		if (my.state.paymentAttempts == 0)
		{
			my.setState({stage: 'paymentfail', paymentReference: clientSecret, paymentAttempts: 1});
		}
		else
		{
			my.setState({stage: 'fail'});
		}
	}

  }

  async handleSubmitPayment(onPaid, onFail, ConfirmPayment, cardElement) {
	  const me = this;
		await this.props.api.stripePaymentIntent(
      function done(data) {
		me.handlePaymentMade(data, ConfirmPayment, cardElement)
      },
      function fail(data) {
		me.handlePaymentFailed()
		//setButtonText('Pay');
      }
	);
  }
  
  async handleSubmitPaymentNative(onPaid, onFail, ConfirmPayment) {
	  const me = this;
		await this.props.api.stripePaymentIntent(
      function done(data) {
		me.handlePaymentMadeNative(data, ConfirmPayment)
      },
      function fail(data) {
		me.handlePaymentFailed()
		//setButtonText('Pay');
      }
	);
  }
  
  
  
  handlePaymentFailed() {
	  if (this.state.paymentAttempts == 0)
	  {
		this.setState({stage: 'paymentfail', paymentAttempts: 1});
	  }
	  else
	  {
		this.setState({stage: 'fail'});
	  }
  }
  

  handleChosenDate(date) {
    this.setState({stage: 'chooseDamageType', date: date});
  }

  handleChosenDamageType(damageType) {
    this.setState({stage: 'chooseDamageLocation', damageType: damageType});
  }

  handleChosenDamageLocation(damageLocation) {
    this.setState({stage: 'photos', damageLocation: damageLocation});
  }

  handleNoAppointment() {
    this.setState({stage: 'noAppointment'});
  }

  handleNoOthers() {
    this.setState({stage: 'shinedirectrules'});
  }

  handleOnLocationNo() {
    this.setState({stage: 'thanks'});
  }

  handleOnLocationContinue() {
    this.setState({stage: 'vehiclelocation'});
  }

  handlePhotoUploads(allPhotos) {
    const nextState = Object.assign(
      {}, this.state, {
        stage: 'submitting',
        allPhotos: allPhotos,
      }
    );
    this.setState(nextState);
    this.submitClaim(nextState);
  }

  startAgain() {
    var state = this.freshState();
    state.stage = 'intro';
    this.setState(state);
	store.dispatch(setDamageLocation(''))
	store.dispatch(setDamageType(''))
	store.dispatch(clearPhotos())
}

  render() {
	  console.log(this.state.stage)
    if (this.state.stage === 'intro') {
      return <SmartIntro onIntroContinue={this.handleIntroContinue}/>;
    } else if (this.state.stage === 'splash') {
      return <SmartSplash onSplashContinue={this.handleSplashContinue}/>;
    } else if (this.state.stage === 'chooseDate') {
      return <SmartDateWeb onDateContinue={this.handleChosenDate}/>;
    } else if (this.state.stage === 'chooseDamageType') {
      return <SmartDamageType onChosen={this.handleChosenDamageType}/>;
    } else if (this.state.stage === 'chooseDamageLocation') {
      return <SmartDamageLocation onDamageChosen={this.handleChosenDamageLocation}/>;
    } else if (this.state.stage === 'photos') {
      return <SmartPhotos damageLocation={this.state.damageLocation} onContinue={this.handlePhotoUploads}/>;
    } else if (this.state.stage === 'submitting') {
      return <SmartSubmitting/>;
    } else if (this.state.stage === 'another') {
      return <SmartAnother onYes={this.startAgain} onNo={this.handleNoOthers}/>;
    } else if (this.state.stage === 'shinedirectrules') {
      if (this.state.meetsShineBusinessRules === 'true') {
		  return <SmartVehicleLocationStatement onLocationNo={this.handleOnLocationNo} onLocationContinue={this.handleOnLocationContinue}/>;
	  }
	  else {
		  this.setState({stage: 'differentReferred'})
			return <SmartDifferentReferred onReferredYes={this.handleOnDone} onReferredNo={this.handleOnDoneNoMoreClaims}/>;
	  }
    } else if (this.state.stage === 'vehiclelocation') {
	return <SmartLocation api={this.props.api}  onLocation2Continue={this.handleLocationContinue} />;
    } else if (this.state.stage === 'chooseRepairDate') {
	return <SmartRepairDate api={this.props.api} vehiclePostcode={this.state.vehiclePostcode} onNoAppointment={this.handleNoAppointment} onRepairDateContinue={this.handleRepairDateContinue} />;
    } else if (this.state.stage === 'noAppointment') {
	return <SmartNoAppointment onDone={this.handleOnDone}/>;
    } else if (this.state.stage === 'payment') {
		if (Platform.OS == "android")
		{
		  return <StripeProvider publishableKey={stripeKey} ><SmartPaymentNative onPaid={this.handlePaymentMade} onSubmitPaymentNative={this.handleSubmitPaymentNative} onFail={this.handlePaymentFailed}/></StripeProvider>;
		}
		else
		{
		  return <Elements stripe={stripePromise}><SmartPayment onPaid={this.handlePaymentMade} onSubmitPayment={this.handleSubmitPayment} onFail={this.handlePaymentFailed}/></Elements>;			
		}
    } else if (this.state.stage === 'payment1') {
      return <SmartPayment api={this.props.api} onPaid={this.handlePaymentMade} onFail={this.handlePaymentFailed}/>;
    } else if (this.state.stage === 'different') {
      return <SmartDifferent onYesAnother={this.handleOnDone} onNo={this.handleOnDoneNoMoreClaims}/>;
    } else if (this.state.stage === 'differentReferred') {
      return <SmartDifferentReferred onReferredYes={this.handleOnDone} onReferredNo={this.handleOnDoneNoMoreClaims}/>;
    } else if (this.state.stage === 'thanks') {
      return <SmartThanks onDone={this.handleOnDone}/>;
    } else if (this.state.stage === 'fail') {
      return <SmartFail onRetry={this.startAgain}/>;
    } else if (this.state.stage === 'paymentfail') {
      return <SmartPaymentFail onRetry={() => this.setState({'stage': 'payment'})}/>;
    }
  }

  submitClaim(state) {
    const component = this;
    this.props.api.smartClaim(
      this.props.policy,
      state.date,
      state.damageType,
      state.damageLocation,
      function done(data) {
        state.claimId = data.claim_id;
		state.meetsShineBusinessRules = data.meetsShineBusinessRules;
        component.setState(state);
        component.submitPhotos(state);
      },
      component.apiFail
    );
  }

  apiFail(xhr) {
    console.log(xhr);
    this.setState({stage: 'fail'});
  }

  submitPhotos(state) {
    const component = this;
    const photos =
      state.allPhotos.map(function(p) { return {type: 'full damage', data: p}; });
    component.props.api.uploadPhotos(
      component.props.api.smartPhoto,
      state.claimId,
      photos,
      function done() { 
		component.setState({stage: 'another'}) 
	  },
      component.apiFail
    );
  }
}

export default Smart;
