import React from 'react';
import DefaultButton from './DefaultButton';
import PhotoPreviewManage from './PhotoPreviewManage';
import PhotosBadgeSelected from './PhotosBadgeSelected';

import popupclosebutton from '../../icon-close2.png'

function PhotoPreviewWithDeleteNew(props) {
  return (
    
    <div className="PhotoPreviewWithDeleteNew" style={{marginBottom: '1rem', fontSize: '0.8rem', width: '50%', marginBottom: '1rem', display: 'inline-block', textAlign: 'center', position: 'relative', marginTop: 40}}>
		<img className="popupclosebuttonnew" src={popupclosebutton} onClick={() => props.onDelete(props.index)} alt="Delete" style={{position: 'absolute', top: -35, right: 0, height: 70, cursor: 'pointer', paddingTop: '0px !important'}} />	
      <PhotoPreviewManage data={props.data}/>
    </div>
  );
}

export default PhotoPreviewWithDeleteNew;
