import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import Styles from '../constants/Styles'
import store from '../redux/Store'
import { setLoss } from '../redux/LossSlice'
import { setPolicy } from '../redux/PolicySlice'

interface WelcomeProps {
  navigation: any
  onContinue: Function
}

export default function Welcome ({ navigation, onContinue }: WelcomeProps) {
  const nextScreen = () => {
    navigation.navigate('StandardSignIn')

    store.dispatch(
      setPolicy({
        id: 1,
        lossTypes: [],
        // coverStartDate: new Date("2021-1-1"),
        durationTerm: 12,
        // expiryDate: new Date("2021-1-1"),
        hasExistingClaim: true,
        isCancellable: false,
        isPendingCancellation: false,
        isPremiumFunded: false,
        isProRataRefundable: false,
        policyNo: 308,
        product: 'Example',
        retailPrice: 200,
        currencyCode: 'code124',
        currencySymbol: 'abc',
        existingClaimEmailAddress: 'marypoppins@example.com',
        existingClaimId: 'abc'
      })
    )

    store.dispatch(
      setLoss({
        id: 1,
        name: 'marypoppins@example.com'
      })
    )
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <View style={styles.container}>
        <Heading>Welcome</Heading>
        <VerticalSpace height={20} />
        <Para>Thank you for using the Shine app. It’s easy to use and quick to make an Asset Protection, SMART, Tyre, Alloy or Complete Wheel claim.</Para>
        <VerticalSpace height={20} />
        <Para>Plus if you have an agreement that can be cancelled you can use the app.</Para>
        <VerticalSpace height={20} />
        <Para>Whatever you need to do, it will not take more than a few minutes to complete.</Para>
        <VerticalSpace height={40} />
        <DefaultButton onPress={() => onContinue()} text="Continue"/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
