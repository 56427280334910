import React from 'react'
import { StyleSheet, View } from 'react-native'
import ScreenHeading from './ScreenHeading'
import VerticalSpace from './VerticalSpace'
import Para from './Para'
import Styles from '../constants/Styles'

interface SubmittingProps {
  heading: string
}

export default function Submitting ({ heading }: SubmittingProps) {
  return (
    <View>
      <ScreenHeading text={heading}/>
      <VerticalSpace height={15}/>
      <ScreenHeading text='Submitting...'/>
      <VerticalSpace height={15}/>
      <View style={styles.container}>
        <Para>
          Please wait while your claim is being submitted.
        </Para>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  }
})
