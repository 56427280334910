import React, { Component } from 'react';
import { StyleSheet, View, Picker } from 'react-native'
import Styles from '../../constants/Styles'

type DatePickerProps = {
  date: Date,
  onChange: (text: string) => void
}

const DatePicker = ({ date, onChange }: DatePickerProps) => {
  const setDayOfMonth = (event : any) => {
    const oldDate = new Date(date);
    let newdate = new Date(
        oldDate.getFullYear(), oldDate.getMonth(), parseInt(event)
    );
	
	date = newdate;
    onChange(newdate);
  }

  const setMonth = (event : any) => {
    const oldDate = new Date(date);
    let newdate = new Date(
        oldDate.getFullYear(), parseInt(event), oldDate.getDate()
    );
	date = newdate;

    onChange(newdate);
  }

  const setFullYear = (event : any) => {
    const oldDate = new Date(date);
    let newdate = new Date(
        parseInt(event), oldDate.getMonth(), oldDate.getDate()
    );
	date = newdate;

    onChange(newdate);
  }
    return (
      <View style={styles.rowContainer}>
		<Picker selectedValue={date.getDate().toString()} style={styles.selectBox} onValueChange={(e) => setDayOfMonth(e)}>
			<Picker.Item value="1" label="1" />
			<Picker.Item value="2" label="2" />
			<Picker.Item value="3" label="3" />
			<Picker.Item value="4" label="4" />
			<Picker.Item value="5" label="5" />
			<Picker.Item value="6" label="6" />
			<Picker.Item value="7" label="7" />
			<Picker.Item value="8" label="8" />
			<Picker.Item value="9" label="9" />
			<Picker.Item value="10" label="10" />
			<Picker.Item value="11" label="11" />
			<Picker.Item value="12" label="12" />
			<Picker.Item value="13" label="13" />
			<Picker.Item value="14" label="14" />
			<Picker.Item value="15" label="15" />
			<Picker.Item value="16" label="16" />
			<Picker.Item value="17" label="17" />
			<Picker.Item value="18" label="18" />
			<Picker.Item value="19" label="19" />
			<Picker.Item value="20" label="20" />
			<Picker.Item value="21" label="21" />
			<Picker.Item value="22" label="22" />
			<Picker.Item value="23" label="23" />
			<Picker.Item value="24" label="24" />
			<Picker.Item value="25" label="25" />
			<Picker.Item value="26" label="26" />
			<Picker.Item value="27" label="27" />
			<Picker.Item value="28" label="28" />
			<Picker.Item value="29" label="29" />
			<Picker.Item value="30" label="30" />
			<Picker.Item value="31" label="31" />
		</Picker>
		<Picker selectedValue={date.getMonth().toString()} style={styles.selectBox} onValueChange={(e) => setMonth(e)}>
          <Picker.Item value="0" label="January" />
          <Picker.Item value="1" label="February" />
          <Picker.Item value="2" label="March" />
          <Picker.Item value="3" label="April" />
          <Picker.Item value="4" label="May" />
          <Picker.Item value="5" label="June" />
          <Picker.Item value="6" label="July" />
          <Picker.Item value="7" label="August" />
          <Picker.Item value="8" label="September" />
          <Picker.Item value="9" label="October" />
          <Picker.Item value="10" label="November" />
          <Picker.Item value="11" label="December" />
		</Picker>
        <Picker selectedValue={date.getFullYear().toString()} onValueChange={(e) => setFullYear(e)} style={styles.selectBox}>
          <Picker.Item value="2019" label="2019" />
          <Picker.Item value="2020" label="2020" />
          <Picker.Item value="2021" label="2021" />
          <Picker.Item value="2022" label="2022" />
        </Picker>
      </View>
    );



}

export default DatePicker;


const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
  },
  selectBox: {
    fontSize: 16,
	margin: 4,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 4,
  }
})
