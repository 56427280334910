import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartDifferentProps {
  navigation: any
  onYesAnother: Function
}

export default function SmartDifferent ({ navigation, onYesAnother }: SmartDifferentProps) {
  const emptyTestFunction = () => {}

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Repair Request"/>
      <View style={styles.container}>
        <Para>
          Thank you.
        </Para>
        <Para>
          You will shortly receive an automated email confirming your appointment details.
        </Para>
        <Para>
          Would you like to submit another repair request?
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onYesAnother} text="Yes"/>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onYesAnother} text="No"/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
