import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import Styles from '../../constants/Styles'
import VerticalSpace from '../../components/VerticalSpace'
import LabeledTextField from '../../components/LabeledTextField'
import DefaultButton from '../../components/DefaultButton'
import ScreenHeading from '../../components/ScreenHeading'


interface RequestCallbackThanksProps {
  navigation: any
  onReturn:Function
}

export default function RequestCallbackThanks ({ navigation, onReturn }: RequestCallbackThanksProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Callback request"/>
      <VerticalSpace height={15} />
      <View style={styles.container}>
		<Para>Thank you.</Para>
		<Para>Your call back request has been received.</Para>
		<Para>We will respond within 24 hours during normal office opening hours.</Para>
		<Para>Office opening hours:<br />Mon – Fri 9am to 5pm<br />Sat - 9am to 1pm<br />Closed Sunday and Bank Holidays.</Para>
        <VerticalSpace height={15}/>
		<DefaultButton onPress={onReturn} text="Return to menu" />
		<VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
