import React, { useState, Component } from 'react'
import { Keyboard, Pressable, StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import ConditionalButton from '../../components/ConditionalButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import Styles from '../../constants/Styles'
import DatePicker from '../../components/DatePicker';
import { useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as moment from 'moment'
import Moment from 'moment';
import VerticalSpace from '../../components/VerticalSpace'

import store, { RootState } from '../../redux/Store'
import { setDamageDate } from '../../redux/DetailsSlice'

interface SmartDateWebProps {
  navigation: any
  onDateContinue: Function
}
 var state = {
      date: new Date()
    };

export default function SmartDateWeb({ navigation, onDateContinue }: SmartDateWebProps) {
  
  const details = useSelector((state: RootState) => state.details.value)
  
  
 

  async function updateDamageDate (text: Date) {
	  //text = text.setDate(text.getDate() + 1);
	  //Moment().tz('Europe/London');
	  state.date = text
	  console.log(state.date)
	  store.dispatch(setDamageDate(Moment(text).format('DD-MM-YYYY')))
    //store.dispatch(setDamageDate(moment()(state.date, 'DD-MM-YYYY').format('DD MMM YYYY')))
	
	//store.dispatch(setDamageDate(moment(state.date, 'DD-MM-YYYY').format('DD MMM YYYY')))
    //await AsyncStorage.setItem('details.damageDate', text)
    await AsyncStorage.setItem('details.damageDate', Moment(text).format('DD MMM YYYY'))
  }
  
  function doContinue()
  {
	  onDateContinue(state.date);
  }


    return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Repair Request"/>
      <View style={styles.container}>
        <Para>
          Please confirm that all the details you have provided within this repair request are in-line with the terms and conditions.
        </Para>
        <Para>
          Note, providing inaccurate information may result in a delay in assessing your repair request.
        </Para>
        <Para>
          By continuing you promise the information provided is true and correct.
        </Para>
        <Para>
          When did you notice the damage? Select a date.
        </Para>
        <VerticalSpace height={15}/>
        <DatePicker date={state.date} onChange={(date) => updateDamageDate(date)}/>
        <VerticalSpace height={15}/>
        <ConditionalButton text="Continue" condition={canSubmit} onPress={doContinue} />
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>

    );

  function canSubmit () {
    return state.date <= new Date();
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})


