import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Another from '../../components/Another'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import ConditionalButton from '../../components/ConditionalButton'
import Para from '../../components/Para'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import Api from '../../components/Api'


interface SmartPaymentProps {
  navigation: any
  onPaid: Function
  onSubmitPayment: Function
  onFail: Function
  clientSecret: string
}



export default function SmartPayment ({ navigation, onPaid, onSubmitPayment, onFail, clientSecret }: SmartPaymentProps) {
  const [buttonText, setButtonText] = useState('Pay')
const stripe = useStripe();
const elements = useElements();

  function canContinue () {
    return buttonText === 'Pay'
  }
  
  async function ConfirmPayment (clientSecret, cardElement, onPaid, onFail) {

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

		const confirmResult = await stripe.confirmCardPayment(clientSecret, {
			  payment_method: {
				card: cardElement
			  },
		});
		
					console.log(confirmResult);

		if (confirmResult.error) {
		  console.log('[error]', confirmResult.error.code);
		  return false;
		} else {
			console.log(confirmResult);
		  return true;
		}
	  
  }


const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
	setButtonText('Please Wait...');
	
	const cardElement = elements.getElement(CardElement);
	
	onSubmitPayment(onPaid, onFail, ConfirmPayment, cardElement);
  };

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
        <ScreenHeading text="Call out charge"/>
        <View style={styles.container}>
          <Para>
            To complete your repair request, please pay our call out charge of &pound;25
          </Para>
		  <CardElement />
          <VerticalSpace height={15}/>
          <ConditionalButton onPress={handleSubmit} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
        </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
