import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Colors from '../constants/Colors'
import VerticalSpace from './VerticalSpace'

type ScreenHeadingProps = {
  text: string
}

const ScreenHeading: FunctionComponent<ScreenHeadingProps> = (props: ScreenHeadingProps) => {
  return (
    <View>
      <View style={styles.textBannerContainer}>
        <Text style={styles.textBanner}>{props.text}</Text>
      </View>
      <VerticalSpace height={5} />
    </View>
  )
}

export default ScreenHeading

const styles = StyleSheet.create({
  textBanner: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center'
  },
  textBannerContainer: {
    // backgroundColor: Colors.red, // AutoProtect
    backgroundColor: Colors.teal, // Shine
    paddingVertical: 8,
    width: '100%'
  }
})
