import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import NavigationBar from '../../components/NavigationBar'
import Another from '../../components/Another'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import ConditionalButton from '../../components/ConditionalButton'
import Para from '../../components/Para'
import Api from '../../components/Api'
import Styles from '../../constants/Styles'
//import { CardField, useConfirmPayment } from '@stripe/stripe-react-native';



interface SmartPaymentNativeProps {
  navigation: any
  onPaid: Function
  onSubmitPaymentNative: Function
  onFail: Function
  clientSecret: string
}



export default function SmartPaymentNative ({ navigation, onPaid, onSubmitPaymentNative, onFail, clientSecret }: SmartPaymentNativeProps) {
  const [buttonText, setButtonText] = useState('Pay')
  const {confirmPayment, loading} = useConfirmPayment()
  function canContinue () {
    return buttonText === 'Pay'
  }
  
  async function ConfirmPayment (clientSecret, cardElement, onPaid, onFail) {

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

		const confirmResult = await confirmPayment(clientSecret, {
			  type: 'Card',
			  
		});
		
					console.log(confirmResult);

		if (confirmResult.error) {
		  console.log('[error]', confirmResult.error.code);
		  return false;
		} else {
			console.log(confirmResult);
		  return true;
		}
	  
  }


const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
	
	setButtonText('Please wait...');
	
	onSubmitPaymentNative(onPaid, onFail, ConfirmPayment);
  };

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
        <ScreenHeading text="Call out charge"/>
        <View style={styles.container}>
          <Para>
            To complete your repair request, please pay our call out charge of &pound;25
          </Para>
		  <CardField
		  style={styles.cardfieldContainer}
			  postalCodeEnabled={true}
			  cardStyle={{
				backgroundColor: '#FFFFFF',
				textColor: '#000000',
			  }}
			/>
          <VerticalSpace height={15}/>
          <ConditionalButton onPress={handleSubmit} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
        </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  },
  cardfieldContainer: {
	width: '100%',
	height: 50,
	marginVertical: 30,
	fontSize: 12
  }
})
