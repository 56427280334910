import 'react-native-gesture-handler'
import React, { ReactElement, useState } from 'react'
import { Text } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import store from './redux/Store'
import { Provider } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { ApiDetails, ApiPolicy } from './ApiTypes'
import SignIn from './screens/SignIn/SignIn'
import Welcome from './screens/Welcome'
import PolicyHolder from './screens/SignIn/PolicyHolder'
import NotPolicyHolder from './screens/SignIn/NotPolicyHolder'
import YourDetails from './screens/SignIn/YourDetails'
import FailedSignIn from './screens/SignIn/FailedSignIn'
import SmartIntro from './screens/Smart/SmartIntro'
import SmartSplash from './screens/Smart/SmartSplash'
import SmartDate from './screens/Smart/SmartDate'
import SmartDamageType from './screens/Smart/SmartDamageType'
import SmartDamageLocation from './screens/Smart/SmartDamageLocation'
import SmartPhotos from './screens/Smart/SmartPhotos'
import YourVehicle from './screens/SignIn/YourVehicle'
import SmartThanks from './screens/Smart/SmartThanks'
import Smart from './screens/Smart/Smart'
import SmartFail from './screens/Smart/SmartFail'
import SmartDifferentReffered from './screens/Smart/SmartDifferentReffered'
import SmartDifferent from './screens/Smart/SmartDifferent'
import SmartAnother from './screens/Smart/SmartAnother'
import SmartSubmitting from './screens/Smart/SmartSubmitting'
import SmartNoAppointment from './screens/Smart/SmartNoAppointment'
import StandardSignIn from './screens/SignIn/StandardSignIn'
import { setEmail, setFirstName, setMobileNumber, setSurname, setRegistrationNumber, setPostcode, setPolicyNumber, setRepeatEmail, setMileage } from './redux/DetailsSlice'
import MultiplePhotoUploadTogether from './screens/Smart/MultiplePhotoUploadTogether'
import CancellationPayment from './screens/Cancellation/CancellationPayment'
import SmartLocation from './screens/Smart/SmartLocation'
import SmartVehicleLocationStatement from './screens/Smart/SmartVehicleLocationStatement'
import Api from './components/Api'
import ProductInfo from './ProductInfo'

  const api = new Api()

export default function App () {
  const [authenticated, setAuthenticated] = useState(false)
  const [details, setDetails] = useState<ApiDetails>({ firstName: '', surname: '', mileage: '', mobileNumber: '', email: '', postcode: '', registrationNumber: '', repeatEmail: '', policyNumber: '' })
  const [policies, setPolicies] = useState<Array<ApiPolicy>>([])
  const [welcomed, setWelcomed] = useState(false)
  
  const onAuthenticated = (details: ApiDetails, policies: Array<ApiPolicy>) => {
    setDetails(details)
    setPolicies(policies)
    setAuthenticated(true)
  }

  const Stack = createStackNavigator()
  
  const startAgain = () => {
    setDetails(null)
    setPolicies(null)
	setAuthenticated(false)
	setWelcomed(false)
  }
  
  const returnToMainMenu = () => {
	  setWelcomed(false)
	  setWelcomed(true)
  }

  const screen = (): ReactElement => {
	  //return <Smart api={api} policy={policy} onDoneNoMoreClaims={() => this.returnToWelcome()} onDone={() => this.returnToMainMenu()}/>
    /*if (!welcomed) {
      return <Welcome onContinue={() => setWelcomed(true)} />
    } else */
	if (authenticated) {
		const productInfo = new ProductInfo(policies);
		const policy = productInfo.policyIncluding("smart");
		if (policy) {
			return <Smart api={api} policy={policy} onDoneNoMoreClaims={startAgain} onDone={startAgain}/>
		}
		else
		{
			return <Welcome onContinue={() => setWelcomed(true)} />
		}
    } else {
      return <SignIn api={api} onAuthenticated={onAuthenticated} />
    }
  }

  async function loadDetails () {
    const firstName = await AsyncStorage.getItem('details.firstName') || ''
    const surname = await AsyncStorage.getItem('details.surname') || ''
    const mobileNumber = await AsyncStorage.getItem('details.mobileNumber') || ''
    const email = await AsyncStorage.getItem('details.email') || ''
    const registrationNumber = await AsyncStorage.getItem('details.registrationNumber') || ''
    const postcode = await AsyncStorage.getItem('details.postcode') || ''
    const repeatEmail = await AsyncStorage.getItem('details.repeatEmail') || ''
    const mileage = await AsyncStorage.getItem('details.mileage') || ''
    const policyNumber = ''
    store.dispatch(setFirstName(firstName))
    store.dispatch(setSurname(surname))
    store.dispatch(setMobileNumber(mobileNumber))
    store.dispatch(setEmail(email))
    store.dispatch(setRegistrationNumber(registrationNumber))
    store.dispatch(setPostcode(postcode))
    store.dispatch(setRepeatEmail(repeatEmail))
    store.dispatch(setMileage(mileage))
    store.dispatch(setPolicyNumber(mileage))
  }

  loadDetails()

  return (
    <Provider store={store}>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            cardStyle: {
              backgroundColor: '#ffffff' // Shine
            },
            headerShown: false
          }}>
          <Stack.Screen name="Welcome" component={screen} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  )
}
