import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'

interface SmartDifferentReferredProps {
  navigation: any
  onReferredYes: Function
  onReferredNo: Function
}

export default function SmartDifferentReferred ({ navigation, onReferredYes, onReferredNo }: SmartDifferentReferredProps) {
  console.log('dr')

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Repair Request"/>
      <View style={styles.container}>
        <Para>
          Thank you for submitting your claim/s, you will shortly receive an automated email confirming the information supplied.
        </Para>
        <Para>
          We’ll review the information and images you have sent us within the next two working days.
        </Para>
        <Para>
          If there is anything else we need, we will be in touch.
        </Para>
          <VerticalSpace height={15}/>
          <DefaultButton onPress={onReferredYes} text="Return to start" />
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
