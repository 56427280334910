import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import ConditionalButton from '../../components/ConditionalButton'
import LabeledTextField from '../../components/LabeledTextField'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'
import store, { RootState } from '../../redux/Store'
import { setEmail, setFirstName, setMileage, setMobileNumber, setRepeatEmail, setSurname } from '../../redux/DetailsSlice'

interface YourDetailsProps {
  navigation: any
  onSubmit: Function
}

export default function YourDetails ({ navigation, onSubmit }: YourDetailsProps) {
  const [buttonText, setButtonText] = useState('Continue')
  const details = useSelector((state: RootState) => state.details.value)

  async function updateFirstName (text: string) {
    store.dispatch(setFirstName(text))
    await AsyncStorage.setItem('details.firstName', text)
  }

  async function updateSurname (text: string) {
    store.dispatch(setSurname(text))
    await AsyncStorage.setItem('details.surname', text)
  }

  async function updateMobileNumber (text: string) {
    store.dispatch(setMobileNumber(text))
    await AsyncStorage.setItem('details.mobileNumber', text)
  }

  async function updateEmail (text: string) {
    store.dispatch(setEmail(text))
    await AsyncStorage.setItem('details.email', text)
  }

  async function updateRepeatEmail (text: string) {
    store.dispatch(setRepeatEmail(text))
    await AsyncStorage.setItem('details.repeatEmail', text)
  }

  async function updateMileage (text: string) {
    store.dispatch(setMileage(text))
    await AsyncStorage.setItem('details.mileage', text)
  }
  
  function canContinue () {
    return details.firstName !== '' && details.surname !== '' && details.mobileNumber !== '' && details.email !== '' && emailValid() && details.mileage !== '' && buttonText === 'Continue'
  }
  
  function  emailValid() {
    return (details.email && (details.email === details.repeatEmail));
  }

  function onFail() {
    setButtonText('Please wait...')
  }
  function onButtonPressed() {
    setButtonText('Please wait...')
    onSubmit(details.firstName, details.surname, details.mobileNumber, details.email, details.mileage, onFail());
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
	  <ScreenHeading text="Agreement Search"/>
      <View style={styles.container}>
        <Para>
          Please enter your details.
        </Para>
        <Para>
          We need all this information – thank you
        </Para>
        <LabeledTextField placeholder="Required" label="First name" onChange={updateFirstName} value={details.firstName}/>
        <LabeledTextField placeholder="Required" label="Surname" onChange={updateSurname} value={details.surname}/>
        <LabeledTextField placeholder="Required" label="Mobile number" onChange={updateMobileNumber} value={details.mobileNumber}/>
        <LabeledTextField placeholder="Required" label="Email" onChange={updateEmail} value={details.email}/>
        <LabeledTextField placeholder="Required" label="Re-enter email" onChange={updateRepeatEmail} value={details.repeatEmail}/>
        <LabeledTextField placeholder="Required" label="Vehicle mileage" onChange={updateMileage} value={details.mileage}/>
        <VerticalSpace height={15}/>
		<ConditionalButton onPress={onButtonPressed} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
