import React from 'react'
import { ApiDamageLocation } from '../../ApiTypes'
import { GestureResponderEvent, Image, ImageSourcePropType, Pressable, StyleSheet, View } from 'react-native'

interface SmartDamageMapProps {
  location: ApiDamageLocation
  onLocationChange: (location: ApiDamageLocation | null) => void
}

const displayImageWidth = 210
const displayImageHeight = 368
const originalImageWidth = 1281
const scaleFactor = originalImageWidth / displayImageWidth

const SmartDamageMap = ({ location, onLocationChange }: SmartDamageMapProps) => {
  const hitTargets: Array<[number, number, number, number, ApiDamageLocation, ImageSourcePropType]> = [
    [0, 261, 387, 627, "driver's side rear arch", require('../../assets/smart-damage/DS_RearArch.png')],
    [0, 627, 380, 1000, "driver's side rear door", require('../../assets/smart-damage/DS_RearDoor.png')],
    [0, 1000, 381, 1431, "driver's side front door", require('../../assets/smart-damage/DS_Door.png')],
    [0, 1656, 321, 2052, "driver's side front arch", require('../../assets/smart-damage/DS_FrontArch.png')],
    [0, 1452, 309, 1635, "driver's side wing mirror", require('../../assets/smart-damage/DS_WingMirror.png')],
    [0, 2061, 303, 2242, "driver's side front bumper corner", require('../../assets/smart-damage/DS_FrontBumperCorner.png')],
    [0, 48, 231, 261, "driver's side rear bumper corner", require('../../assets/smart-damage/DS_RearBumperCorner.png')],
    [890, 261, 1281, 627, "passenger's side rear arch", require('../../assets/smart-damage/PS_RearArch.png')],
    [900, 627, 1281, 1000, "passenger's side rear door", require('../../assets/smart-damage/PS_RearDoor.png')],
    [890, 995, 1272, 1450, "passenger's side front door", require('../../assets/smart-damage/PS_Door.png')],
    [975, 1656, 1263, 2043, "passenger's side front arch", require('../../assets/smart-damage/PS_FrontArch.png')],
    [960, 1452, 1281, 1635, "passenger's side wing mirror", require('../../assets/smart-damage/PS_WingMirror.png')],
    [987, 2061, 1221, 2242, "passenger's side front bumper corner", require('../../assets/smart-damage/PS_FrontBumperCorner.png')],
    [1029, 48, 1281, 261, "passenger's side rear bumper corner", require('../../assets/smart-damage/PS_RearBumperCorner.png')],
    [399, 210, 888, 558, 'boot', require('../../assets/smart-damage/Boot.png')],
    [400, 790, 885, 1360, 'roof', require('../../assets/smart-damage/Roof.png')],
    [336, 1626, 939, 2043, 'bonnet', require('../../assets/smart-damage/Bonnet.png')],
    [310, 0, 960, 235, 'rear', require('../../assets/smart-damage/Rear.png')],
    [312, 2046, 957, 2242, 'front', require('../../assets/smart-damage/Front.png')]
  ]
  // This does not work on desktop version
  const handlePress = (e: GestureResponderEvent) => {
    const x = (e.nativeEvent.locationX || e.nativeEvent.offsetX) * scaleFactor
    const y = (e.nativeEvent.locationY || e.nativeEvent.offsetY) * scaleFactor

    for (let i = 0; i < hitTargets.length; i++) {
      const ht = hitTargets[i]
      if (x >= ht[0] && y >= ht[1] && x <= ht[2] && y < ht[3]) {
        updateLocation(ht[4])
        return
      }
    }
    updateLocation(null)
  }

  const updateLocation = (location: ApiDamageLocation | null) => {
    onLocationChange(location)
  }

  let selectedImage = require('../../assets/smart-damage/Normal.png')
  for (let i = 0; i < hitTargets.length; i++) {
    const ht = hitTargets[i]
    if (ht[4] === location) {
      selectedImage = ht[5]
    }
  }

  return (
    <Pressable onPress={handlePress} style={styles.container}>
      <View style={styles.backgroundContainer}>
        <Image source={selectedImage} resizeMode="contain" style={styles.backdrop} />
      </View>
    </Pressable>
  )
}

export default SmartDamageMap

const styles = StyleSheet.create({
  backdrop: {
    height: displayImageHeight,
    width: displayImageWidth,
  },
  backgroundContainer: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    height: displayImageHeight,
    width: displayImageWidth,
	margin: 'auto',
	alignSelf: 'center',
  }
})
