import React from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../../components/DefaultButton'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../constants/Styles'
import PhoneButton from '../../components/PhoneButton';

interface FailedSignInProps {
  navigation: any
  onTryAgain: Function
  onRequestCallback: Function
}

export default function FailedSignIn ({ navigation, onTryAgain, onRequestCallback }: FailedSignInProps) {
  const emptyTestFunction = () => {}

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <ScreenHeading text="Sorry"/>
      <View style={styles.container}>
        <Para>
          Ok, no need to worry. You can either tap the button below to try again or call the number below and one of the team will investigate and get the problem sorted.
        </Para>
        <Para>
          Remember we only need the digits from your agreement number, not the letters.
        </Para>
        <VerticalSpace height={15}/>
        <DefaultButton onPress={onTryAgain} text="Try Again"/>
        <VerticalSpace height={15}/>
		<Para>
          Call customer services.
		</Para>
		<Para>Office opening hours:<br />Mon – Fri 9am to 5pm<br />Sat - 9am to 1pm<br />Closed Sunday and Bank Holidays.</Para>
		<VerticalSpace height={15}/>
		<DefaultButton onPress={onRequestCallback} text="Request callback" />
		<VerticalSpace height={15}/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
