import React, { Component } from 'react';
import popupclosebutton from '../../icon-close.png'

class PhotoPreviewManage extends Component {
	
	constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      isOpen: false
    }
	  const handleShowDialog = () => {
		this.setState({ isOpen: !this.state.isOpen });
		console.log("clicked");
	  };
  }
	/*state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log("clicked");
  };*/
  
  
  render() {
    if (this.props.data) {
      return(
        <div className="PhotoPreviewManage" style={{textAlign: 'center', height: 400}}>
          <img className="initialimage" src={this.props.data} onClick={this.handleShowDialog} alt="preview" style={{width: '90%', height: '80%', objectFit: 'cover'}}/>
		{this.state.isOpen && (
          <dialog
            className="dialog"
            style={{ position: "fixed" }}
            open
            onClick={this.handleShowDialog}
			  style={{left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.8)', top: 0, zIndex: 999999}}
          >
            <img
              className="popupclosebutton"
              src={popupclosebutton}
              onClick={this.handleShowDialog}
              alt="preview"
			  style={{position: 'absolute', top: 30, right: 80, height: 70, cursor: 'pointer', paddingTop: '0px !important'}}
            />
            <img
              className="popupimage"
              src={this.props.data}
              onClick={this.handleShowDialog}
              alt="preview"
			  style={{maxHeight: 'none', paddingTop: 115, maxWidth: '70%'}}
            />
          </dialog>
        )}		  
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PhotoPreviewManage;
